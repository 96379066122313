import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { usePageContext } from '../context/page-context'
import { global } from '../styles/global'
import { lg, xl } from '../utils/breakpoints'
import Link from './link'
import Select from './select'

const LinkWrapper = styled.li`
  ${tw`mx-10 first-of-type:ml-0`}
`

const SubNav = ({ links, style, currentPath, selectBackground, clipBackground, onClick }) => {
  const { originalPath } = usePageContext()

  const LinkStyle = (label) => css`
    &:before {
      ${tw`invisible block h-0 overflow-hidden font-bold`}
      content: '${label}';
    }

    ${tw`block pb-6 font-medium transition-all duration-150 ease-in border-b-4 border-transparent pt-7 text-primary-500 text-opacity-60`}
  `

  const LinkActiveStyle = tw`text-opacity-100 border-primary-500`

  const options = useMemo(() =>
    links.map((link) => ({ label: link.label, value: link.link, isLink: true }))
  )

  const defaultOption = useMemo(() =>
    options.find((o) => o.value === (currentPath || originalPath))
  )

  return (
    <nav css={[tw`bg-primary-50`, style]}>
      <div css={[global`layout.desktop-container`, global`layout.grid`, tw`px-0`]}>
        <Select
          style={css`
            ${tw`col-span-4 text-lg font-medium md:col-span-8 lg:hidden`}
            > div:first-of-type {
              ${tw`py-6`}
            }
          `}
          backgroundColor={selectBackground}
          options={options}
          defaultValue={defaultOption}
        />
        <ul css={tw`hidden lg:(flex flex-row col-span-12) xl:col-span-11`}>
          {links.map((link) => (
            <LinkWrapper key={link.label} css={global`base.hover-underline`()}>
              <Link
                to={link.link}
                style={[LinkStyle(link.label), link.active && LinkActiveStyle]}
                activeStyle={LinkActiveStyle}
                onClick={onClick}
              >
                {link.label}
                {link.superscript ? <sup css={tw`ml-1.5`}>{link.superscript}</sup> : undefined}
              </Link>
            </LinkWrapper>
          ))}
        </ul>
        {clipBackground && (
          <div
            css={css`
              ${tw`bg-white hidden xl:(block col-span-5)`}
              ${lg} {
                --containerWidth: ${theme`maxWidth.lg`};
                margin-right: calc((100vw - var(--containerWidth)) / -2);
              }
              ${xl} {
                --containerWidth: ${theme`maxWidth.xl`};
                margin-right: calc((100vw - var(--containerWidth)) / -2);
              }
            `}
          />
        )}
      </div>
    </nav>
  )
}

SubNav.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
  style: PropTypes.object,
  currentPath: PropTypes.string,
  selectBackground: PropTypes.string,
  clipBackground: PropTypes.bool,
  onClick: PropTypes.func,
}

SubNav.defaultProps = {
  clipBackground: false,
}

export default SubNav
